/* ==========================================================================
   #ADMIN OVERRIDES
   Overriding default admin theme
   ========================================================================== */

/* stylelint-disable */

body {
  background-color: $color-grey-super-light;
  font-family: Helvetica, sans-serif;
}

body:not(.folded) {
  @media (min-width: $admin-bp-large) {
    margin-top: $page-space-top;
  }
}

#adminmenu,
#adminmenuwrap,
#adminmenuback {
  width: $sidebar-width;
}

#adminmenu,
#adminmenuwrap,
#adminmenuback {
  background-color: $color-grey-super-light;
}

#adminmenu .opensub .wp-submenu li.current a, #adminmenu .wp-submenu li.current, #adminmenu .wp-submenu li.current a, #adminmenu .wp-submenu li.current a:focus, #adminmenu .wp-submenu li.current a:hover, #adminmenu a.wp-has-current-submenu:focus+.wp-submenu li.current a,
#adminmenu a,
#adminmenu .wp-submenu a,
#adminmenu .wp-submenu li.current a,
#adminmenu div.wp-menu-image:before {
  color: $color-black;
}

#adminmenu .wp-submenu,
#adminmenu li.menu-top:hover, #adminmenu li.opensub>a.menu-top, #adminmenu li>a.menu-top:focus,
#adminmenu .wp-has-current-submenu .wp-submenu,
#adminmenu .wp-has-current-submenu .wp-submenu.sub-open,
#adminmenu .wp-has-current-submenu.opensub .wp-submenu,
#adminmenu a.wp-has-current-submenu:focus+.wp-submenu,
.no-js li.wp-has-current-submenu:hover .wp-submenu,
#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow, #adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
  background-color: $color-white;
  color: $color-black;

  div.wp-menu-image:before {
    color: $color-black;
  }
}

#adminmenu .wp-has-current-submenu .wp-submenu {
  width: $sidebar-width;
  background-color: $color-white;
}

// hide sub arrow
#adminmenu li.wp-has-submenu.wp-not-current-submenu.opensub:hover:after,
ul#adminmenu a.wp-has-current-submenu:after,
ul#adminmenu>li.current>a.current:after {
  display: none;
}

#adminmenu a.menu-top:not(.wp-has-current-submenu):focus+.wp-submenu {
  left: $sidebar-width;
}

@media (min-width: $admin-bp-large) {

  body:not(.folded) #adminmenu a.menu-top {
    padding: rem-calc(8 10 8 30);
    font-size: rem-calc(18);
  }

  body:not(.folded)  #adminmenu .wp-has-current-submenu ul > li > a {
    padding-left: rem-calc(38);
  }

  body:not(.folded) #adminmenu li.wp-has-current-submenu a.wp-has-current-submenu {
    padding-left: rem-calc(30);
    padding-bottom: 0;
  }

}

#adminmenu .wp-submenu {
  left: $sidebar-width;
  border-top: 1px solid $color-grey-very-light;
}

#adminmenu .wp-submenu .wp-submenu-head {
  color: $color-black;
  font-size: rem-calc(18);
  font-weight: bold;
}

#adminmenu .wp-submenu a {
  font-size: rem-calc(16);
}

#adminmenu > li.menu-top,
#adminmenu > li {
  border-bottom: 1px solid $color-grey-super-light;
}

#adminmenu .wp-submenu li.current a:hover,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top>a:focus {
  color: $color-grey-dark;
}

#adminmenu .dashicons-before::before {
  width: rem-calc(28);
  height: rem-calc(28);
  font-size: rem-calc(28);
  line-height: rem-calc(20);
}

#wpfooter,
#wpcontent {
  margin-left: $sidebar-width;
}

#wpcontent {
  padding-left: rem-calc(45);
  background-color: $color-white;
}

#wpbody-content > .wrap {
  margin: 0;
  background-color: $color-grey-super-light;
  padding: rem-calc(30);
  border-top: 45px solid $color-white;
}

.wrap h1 {
  font-size: rem-calc(30);
  font-weight: bold;
  margin-bottom: rem-calc(20);
}

body:not(.folded) #adminmenuwrap {
  background: url('/wp-content/themes/city-of-gretna/dist/assets/images/press-logo.png') 30px 30px no-repeat;
  background-size: 65%;
  padding-top: calc(#{$page-space-top} + 25px);
  margin-top: -$page-space-top;
}

// adminbar
#wpadminbar {
  background-color: $color-grey-very-light;
  color: $color-black;
}

#wpadminbar #adminbarsearch:before, #wpadminbar .ab-icon:before, #wpadminbar .ab-item:before,
#wpadminbar .ab-empty-item, #wpadminbar a.ab-item, #wpadminbar>#wp-toolbar span.ab-label, #wpadminbar>#wp-toolbar span.noticon {
  color: $color-black
}

#wpadminbar .menupop .ab-sub-wrapper, #wpadminbar .shortlink-input,
#wpadminbar .ab-top-menu>li.hover>.ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu>li>.ab-item:focus, #wpadminbar:not(.mobile) .ab-top-menu>li:hover>.ab-item, #wpadminbar:not(.mobile) .ab-top-menu>li>.ab-item:focus {
  background-color: $color-white;
  color: $color-black;
}

#wpadminbar:not(.mobile)>#wp-toolbar a:focus span.ab-label, #wpadminbar:not(.mobile)>#wp-toolbar li:hover span.ab-label, #wpadminbar>#wp-toolbar li.hover span.ab-label,
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover>a, #wpadminbar .quicklinks .menupop ul li a:focus, #wpadminbar .quicklinks .menupop ul li a:focus strong, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a:hover strong, #wpadminbar .quicklinks .menupop.hover ul li a:focus, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:focus, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:hover, #wpadminbar li #adminbarsearch.adminbar-focused:before, #wpadminbar li .ab-item:focus .ab-icon:before, #wpadminbar li .ab-item:focus:before, #wpadminbar li a:focus .ab-icon:before, #wpadminbar li.hover .ab-icon:before, #wpadminbar li.hover .ab-item:before, #wpadminbar li:hover #adminbarsearch:before, #wpadminbar li:hover .ab-icon:before, #wpadminbar li:hover .ab-item:before, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover {
  color: $color-black;
}

#wpadminbar .ab-submenu .ab-item, #wpadminbar .quicklinks .menupop ul li a, #wpadminbar .quicklinks .menupop ul li a strong, #wpadminbar .quicklinks .menupop.hover ul li a, #wpadminbar.nojs .quicklinks .menupop:hover ul li a {
  color: $color-black;

  &:hover {
    color: $color-grey-dark;
  }
}

// hide WP menu from top-bar
#wpadminbar li#wp-admin-bar-wp-logo {
  display: none;
}

#collapse-button:focus,
#collapse-button:hover {
  color: $color-grey-dark;
}

// login area
.login h1 a {
  background-image: url('/wp-content/themes/city-of-gretna/dist/assets/images/press-logo.png');
  height: rem-calc(120);
  background-size: 100%;
  width: 60%;
}

// MailPoet Plugin icon
a.toplevel_page_mailpoet-newsletters .dashicons-before {
  &::before {
    content: '\f465';
  }

  img {
    display: none;
  }
}

// AAM Plugin icon
a.toplevel_page_aam .dashicons-before {
  &::before {
    content: '\f307';
  }

  img {
    display: none;
  }
}

/* New Gravity Forms UI Adjustments
   ========================================================================== */

body.wp-admin.toplevel_page_gf_edit_forms {
  #wpcontent {
    padding-left: 2.8125rem;
  }

  .gform-form-toolbar {
    width: calc(100% - 270px);
    background-color: $color-grey-super-light;
  }

  .editor-sidebar {
    @include breakpoint(medium) {
      top: rem-calc(141px);
    }
  }
}

#wpbody-content .wrap.gforms_edit_form {
  padding-top: 0;
  padding-left: 0;

  .form_editor_fields_container {
    max-width: calc(100% - 704px);
  }
}

/* Media Library Enhancer
   ========================================================================== */

body.upload-php.eml-grid #wpbody {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  height: auto;
}

.media-frame.mode-eml-grid {
  left: 30px;
  right: 30px;
  top: 90px;
}

.message.error ~ .media-frame {
  top: 130px;
}

.media-frame.mode-eml-grid .attachments-browser .attachments {
  left: 0;
  position: absolute;
}

// hide timber notice on plugins page.
.wp-list-table.plugins {
  #the-list tr[data-slug="timber-library"] + tr .plugin-update {
    display: none !important;
  }
}
