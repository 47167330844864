/*
  Custom WP Admin (Dashboard)
  Overrides & Extends
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #ADMIN OVERRIDES
   Overriding default admin theme
   ========================================================================== */
/* stylelint-disable */
body {
  background-color: #fafafa;
  font-family: Helvetica, sans-serif;
}

@media (min-width: 60.0625rem) {
  body:not(.folded) {
    margin-top: 5.625rem;
  }
}

#adminmenu,
#adminmenuwrap,
#adminmenuback {
  width: 14.0625rem;
}

#adminmenu,
#adminmenuwrap,
#adminmenuback {
  background-color: #fafafa;
}

#adminmenu .opensub .wp-submenu li.current a, #adminmenu .wp-submenu li.current, #adminmenu .wp-submenu li.current a, #adminmenu .wp-submenu li.current a:focus, #adminmenu .wp-submenu li.current a:hover, #adminmenu a.wp-has-current-submenu:focus + .wp-submenu li.current a,
#adminmenu a,
#adminmenu .wp-submenu a,
#adminmenu .wp-submenu li.current a,
#adminmenu div.wp-menu-image:before {
  color: #000;
}

#adminmenu .wp-submenu,
#adminmenu li.menu-top:hover, #adminmenu li.opensub > a.menu-top, #adminmenu li > a.menu-top:focus,
#adminmenu .wp-has-current-submenu .wp-submenu,
#adminmenu .wp-has-current-submenu .wp-submenu.sub-open,
#adminmenu .wp-has-current-submenu.opensub .wp-submenu,
#adminmenu a.wp-has-current-submenu:focus + .wp-submenu,
.no-js li.wp-has-current-submenu:hover .wp-submenu,
#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow, #adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
  background-color: #fff;
  color: #000;
}
#adminmenu .wp-submenu div.wp-menu-image:before,
#adminmenu li.menu-top:hover div.wp-menu-image:before, #adminmenu li.opensub > a.menu-top div.wp-menu-image:before, #adminmenu li > a.menu-top:focus div.wp-menu-image:before,
#adminmenu .wp-has-current-submenu .wp-submenu div.wp-menu-image:before,
#adminmenu .wp-has-current-submenu .wp-submenu.sub-open div.wp-menu-image:before,
#adminmenu .wp-has-current-submenu.opensub .wp-submenu div.wp-menu-image:before,
#adminmenu a.wp-has-current-submenu:focus + .wp-submenu div.wp-menu-image:before,
.no-js li.wp-has-current-submenu:hover .wp-submenu div.wp-menu-image:before,
#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head div.wp-menu-image:before,
#adminmenu .wp-menu-arrow div.wp-menu-image:before, #adminmenu .wp-menu-arrow div div.wp-menu-image:before,
#adminmenu li.current a.menu-top div.wp-menu-image:before,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu div.wp-menu-image:before,
.folded #adminmenu li.current.menu-top div.wp-menu-image:before,
.folded #adminmenu li.wp-has-current-submenu div.wp-menu-image:before {
  color: #000;
}

#adminmenu .wp-has-current-submenu .wp-submenu {
  width: 14.0625rem;
  background-color: #fff;
}

#adminmenu li.wp-has-submenu.wp-not-current-submenu.opensub:hover:after,
ul#adminmenu a.wp-has-current-submenu:after,
ul#adminmenu > li.current > a.current:after {
  display: none;
}

#adminmenu a.menu-top:not(.wp-has-current-submenu):focus + .wp-submenu {
  left: 14.0625rem;
}

@media (min-width: 60.0625rem) {
  body:not(.folded) #adminmenu a.menu-top {
    padding: 0.5rem 0.625rem 0.5rem 1.875rem;
    font-size: 1.125rem;
  }

  body:not(.folded) #adminmenu .wp-has-current-submenu ul > li > a {
    padding-left: 2.375rem;
  }

  body:not(.folded) #adminmenu li.wp-has-current-submenu a.wp-has-current-submenu {
    padding-left: 1.875rem;
    padding-bottom: 0;
  }
}
#adminmenu .wp-submenu {
  left: 14.0625rem;
  border-top: 1px solid #d9d9d9;
}

#adminmenu .wp-submenu .wp-submenu-head {
  color: #000;
  font-size: 1.125rem;
  font-weight: bold;
}

#adminmenu .wp-submenu a {
  font-size: 1rem;
}

#adminmenu > li.menu-top,
#adminmenu > li {
  border-bottom: 1px solid #fafafa;
}

#adminmenu .wp-submenu li.current a:hover,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top > a:focus {
  color: #101010;
}

#adminmenu .dashicons-before::before {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.75rem;
  line-height: 1.25rem;
}

#wpfooter,
#wpcontent {
  margin-left: 14.0625rem;
}

#wpcontent {
  padding-left: 2.8125rem;
  background-color: #fff;
}

#wpbody-content > .wrap {
  margin: 0;
  background-color: #fafafa;
  padding: 1.875rem;
  border-top: 45px solid #fff;
}

.wrap h1 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

body:not(.folded) #adminmenuwrap {
  background: url("/wp-content/themes/city-of-gretna/dist/assets/images/press-logo.png") 30px 30px no-repeat;
  background-size: 65%;
  padding-top: calc(5.625rem + 25px);
  margin-top: -5.625rem;
}

#wpadminbar {
  background-color: #d9d9d9;
  color: #000;
}

#wpadminbar #adminbarsearch:before, #wpadminbar .ab-icon:before, #wpadminbar .ab-item:before,
#wpadminbar .ab-empty-item, #wpadminbar a.ab-item, #wpadminbar > #wp-toolbar span.ab-label, #wpadminbar > #wp-toolbar span.noticon {
  color: #000;
}

#wpadminbar .menupop .ab-sub-wrapper, #wpadminbar .shortlink-input,
#wpadminbar .ab-top-menu > li.hover > .ab-item, #wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus, #wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item, #wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus {
  background-color: #fff;
  color: #000;
}

#wpadminbar:not(.mobile) > #wp-toolbar a:focus span.ab-label, #wpadminbar:not(.mobile) > #wp-toolbar li:hover span.ab-label, #wpadminbar > #wp-toolbar li.hover span.ab-label,
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a, #wpadminbar .quicklinks .menupop ul li a:focus, #wpadminbar .quicklinks .menupop ul li a:focus strong, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a:hover strong, #wpadminbar .quicklinks .menupop.hover ul li a:focus, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:focus, #wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:hover, #wpadminbar li #adminbarsearch.adminbar-focused:before, #wpadminbar li .ab-item:focus .ab-icon:before, #wpadminbar li .ab-item:focus:before, #wpadminbar li a:focus .ab-icon:before, #wpadminbar li.hover .ab-icon:before, #wpadminbar li.hover .ab-item:before, #wpadminbar li:hover #adminbarsearch:before, #wpadminbar li:hover .ab-icon:before, #wpadminbar li:hover .ab-item:before, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover {
  color: #000;
}

#wpadminbar .ab-submenu .ab-item, #wpadminbar .quicklinks .menupop ul li a, #wpadminbar .quicklinks .menupop ul li a strong, #wpadminbar .quicklinks .menupop.hover ul li a, #wpadminbar.nojs .quicklinks .menupop:hover ul li a {
  color: #000;
}
#wpadminbar .ab-submenu .ab-item:hover, #wpadminbar .quicklinks .menupop ul li a:hover, #wpadminbar .quicklinks .menupop ul li a strong:hover, #wpadminbar .quicklinks .menupop.hover ul li a:hover, #wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover {
  color: #101010;
}

#wpadminbar li#wp-admin-bar-wp-logo {
  display: none;
}

#collapse-button:focus,
#collapse-button:hover {
  color: #101010;
}

.login h1 a {
  background-image: url("/wp-content/themes/city-of-gretna/dist/assets/images/press-logo.png");
  height: 7.5rem;
  background-size: 100%;
  width: 60%;
}

a.toplevel_page_mailpoet-newsletters .dashicons-before::before {
  content: "\f465";
}
a.toplevel_page_mailpoet-newsletters .dashicons-before img {
  display: none;
}

a.toplevel_page_aam .dashicons-before::before {
  content: "\f307";
}
a.toplevel_page_aam .dashicons-before img {
  display: none;
}

/* New Gravity Forms UI Adjustments
   ========================================================================== */
body.wp-admin.toplevel_page_gf_edit_forms #wpcontent {
  padding-left: 2.8125rem;
}
body.wp-admin.toplevel_page_gf_edit_forms .gform-form-toolbar {
  width: calc(100% - 270px);
  background-color: #fafafa;
}
@media print, screen and (min-width: 48.0625em) {
  body.wp-admin.toplevel_page_gf_edit_forms .editor-sidebar {
    top: 8.8125rem;
  }
}

#wpbody-content .wrap.gforms_edit_form {
  padding-top: 0;
  padding-left: 0;
}
#wpbody-content .wrap.gforms_edit_form .form_editor_fields_container {
  max-width: calc(100% - 704px);
}

/* Media Library Enhancer
   ========================================================================== */
body.upload-php.eml-grid #wpbody {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  height: auto;
}

.media-frame.mode-eml-grid {
  left: 30px;
  right: 30px;
  top: 90px;
}

.message.error ~ .media-frame {
  top: 130px;
}

.media-frame.mode-eml-grid .attachments-browser .attachments {
  left: 0;
  position: absolute;
}

.wp-list-table.plugins #the-list tr[data-slug=timber-library] + tr .plugin-update {
  display: none !important;
}